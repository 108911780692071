import React from 'react';
import {
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowDown,
} from 'react-icons/hi';

const Step = ({ stepNum, steptext, showArrow }) => {
  const arrowStyle = 'text-primary-light text-5xl';
  const roundBorder =
    'border rounded-full border-2 border-solid h-10 w-10 grid place-items-center mx-auto border-primary-light';

  return (
    <div className='flex flex-col md:flex-row gap-4'>
      <div>
        <p className={roundBorder}>{stepNum}</p>
        <p className='mt-2'>{steptext}</p>
      </div>
      <div className='flex items-start justify-center'>
        <HiOutlineArrowNarrowRight
          className={
            showArrow ? arrowStyle + ' hidden md:block' : arrowStyle + ' hidden'
          }
        />
        <HiOutlineArrowNarrowDown
          className={
            showArrow ? arrowStyle + ' md:hidden' : arrowStyle + ' hidden'
          }
        />
      </div>
    </div>
  );
};

export default Step;
