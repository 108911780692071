import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Rings } from 'react-loading-icons';
import * as Yup from 'yup';

const ResestPassword = () => {
  const backgroundGradientStyle = {
    background:
      'linear-gradient(to bottom,  #39acb7 0%,#39acb7 70%,white 70%,white 100%)',
  };
  const history = useHistory();
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [steps, setSteps] = useState({
    emailRequired: true,
    requestingCode: false,
    codeRequested: false,
    requestingPasswordChange: false,
    passwordChanged: false,
  });

  const updateSetSteps = (params) =>
    setSteps({
      emailRequired: false,
      requestingCode: false,
      codeRequested: false,
      requestingPasswordChange: false,
      passwordChanged: false,
      ...params,
    });

  useEffect(() => {
    if (getQueryVariable('email')) {
      // setEmail(getQueryVariable('email'));
    }
  }, []);

  const emailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email'),
    }),
    onSubmit: async (values) => {
      updateSetSteps({ emailRequired: true, requestingCode: true });
      await handleSendCodeClick(values);
      updateSetSteps({ codeRequested: true });
    },
  });

  async function handleSendCodeClick(params) {
    try {
      const { email } = params;
      await Auth.forgotPassword(email);
    } catch (error) {
      updateSetSteps({ emailRequired: true });
      setFeedbackMessage(error.message);
    }
  }

  const resetPasswordFormik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Please enter confirmation code'),
      password: Yup.string()
        .required('Please enter your password')
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
      confirmPassword: Yup.string()
        .required('Please confirm your password')
        .when('password', {
          is: (password) => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            "Password doesn't match"
          ),
        }),
    }),
    onSubmit: async (values) => {
      updateSetSteps({ codeRequested: true, requestingPasswordChange: true });
      await handleChangePasswordClick(values);
      updateSetSteps({ passwordChanged: true });
    },
  });

  async function handleChangePasswordClick(params) {
    try {
      const { code, password } = params;
      const email = emailFormik.values.email;
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (error) {
      updateSetSteps({ codeRequested: true });
      setFeedbackMessage(error.message);
    }
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  function renderEmailForm() {
    return (
      <div class=' w-11/12 md:w-auto flex flex-col mx-auto  p-4 md:pt-16 md:pl-36 md:pr-36 md:pb-16 bg-white shadow-lg shadow-gray-300 hover:shadow-gray-400 rounded transition-all duration-300'>
        <div class='flex md:ml-16 md:mr-16 w-100'>
          <h1 class='mx-auto pb-8 pt-8 md:pt-auto text-2xl'>
            Forgot your password
          </h1>
        </div>
        <div class='flex md:w-100'>
          <h1 class='mx-auto p-2 text-base'>
            Please enter the email address to reset your password
          </h1>
        </div>
        <form onSubmit={emailFormik.handleSubmit}>
          <div class='flex flex-col pt-2 pb-2'>
            <input
              class='p-2 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
              type='text'
              name='email'
              placeholder='Email'
              onChange={emailFormik.handleChange}
              value={emailFormik.values.email}
            />
            <p class='text-red-600 text-xs p-0.5'>
              {emailFormik.errors.email ? emailFormik.errors.email : null}
            </p>

            {!steps.requestingCode ? (
              <button
                type='submit'
                className={`mt-8 p-2 text-gray-600 bg-gray-200 transition-all ease-linear duration-100 hover:bg-primary hover:text-white rounded hover:shadow-lg hover:shadow-gray-300`}
              >
                SEND CONFIRMATION CODE
              </button>
            ) : (
              <div class='flex p-2'>
                <div class='mx-auto'>
                  <Rings stroke='#39acb7' speed={1.5} />
                </div>
              </div>
            )}
          </div>
          <div class='flex flex-col'>
            <div class='mx-auto'>
              <p class='text-red-600 text-sm p-0.5'>{feedbackMessage}</p>
            </div>
          </div>
        </form>

        <div class='flex flex-col p-4 mt-8'>
          <Link
            class='mx-auto text-sm cursor-pointer p-0.5 hover:text-primary'
            to='/login'
          >
            <h1>Back to login?</h1>
          </Link>
        </div>
      </div>
    );
  }

  function renderConfirmationForm() {
    return (
      <div class='flex flex-col mx-auto max-w-2xl  p-4 md:pt-16 md:pl-36 md:pr-36 md:pb-16 bg-white shadow-lg shadow-gray-300 hover:shadow-gray-400 rounded transition-all duration-300'>
        <div class='flex md:ml-8 md:mr-8'>
          <h1 class='mx-auto pb-8 text-2xl'>Create a new Password</h1>
        </div>
        <div class='flex'>
          <h1 class='mx-auto p-2 text-sm'>
            If an account exists with <b>{emailFormik.values.email}</b>,an email
            has been sent with the confirmation code.
          </h1>
        </div>
        <form onSubmit={resetPasswordFormik.handleSubmit}>
          <div class='flex flex-col pt-2 pb-2'>
            <input
              class='p-2 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
              type='text'
              name='code'
              placeholder='Enter confirmation code'
              onChange={resetPasswordFormik.handleChange}
              value={resetPasswordFormik.values.code}
            />
            <p class='text-red-600 text-xs p-0.5'>
              {resetPasswordFormik.errors.code ?? null}
            </p>

            <input
              class='p-2 mt-4 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
              type='password'
              name='password'
              placeholder='New password'
              onChange={resetPasswordFormik.handleChange}
              value={resetPasswordFormik.values.password}
            />
            <p class='text-red-600 text-xs p-0.5'>
              {resetPasswordFormik.errors.password ?? null}
            </p>

            <input
              class='p-2 mt-4 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
              type='password'
              name='confirmPassword'
              placeholder='Confirm password'
              onChange={resetPasswordFormik.handleChange}
              value={resetPasswordFormik.values.confirmPassword}
            />
            <p class='text-red-600 text-xs p-0.5'>
              {resetPasswordFormik.errors.confirmPassword ?? null}
            </p>

            {!steps.requestingPasswordChange ? (
              <button
                type='submit'
                className={`mt-8 p-2 text-gray-600 bg-gray-200 transition-all ease-linear duration-100 hover:bg-primary hover:text-white rounded hover:shadow-lg hover:shadow-gray-300`}
              >
                CHANGE PASSWORD
              </button>
            ) : (
              <div class='flex p-2'>
                <div class='mx-auto'>
                  <Rings stroke='#39acb7' speed={1.5} />{' '}
                </div>
              </div>
            )}
          </div>
          <div class='flex flex-col'>
            <div class='mx-auto'>
              <p class='text-red-600 text-sm p-0.5'>{feedbackMessage}</p>
            </div>
          </div>
        </form>

        <div class='flex flex-col p-4 mt-8'>
          <Link
            class='mx-auto text-sm cursor-pointer p-0.5 hover:text-primary'
            to='/login'
          >
            <h1>Back to login?</h1>
          </Link>
        </div>
      </div>
    );
  }

  function renderSuccessScreen() {
    setTimeout(() => {
      history.push('/');
    }, 5000);
    return (
      <div class='flex flex-col max-w-2xl mx-auto  p-4 md:pt-16 md:pl-4 md:pr-4 md:pb-16 bg-white shadow-lg shadow-gray-300 hover:shadow-gray-400 rounded transition-all duration-300'>
        <div class='flex md:ml-16 md:mr-16'>
          <h1 class='mx-auto pb-8 text-2xl text-center'>
            Your password has been changed successfully
          </h1>
        </div>
        <div class='flex'>
          <h1 class='mx-auto p-2 text-base'>
            You are being redirected to login
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div class='h-screen relative'>
        <div
          class='flex flex-col h-full justify-evenly'
          style={backgroundGradientStyle}
        >
          <div class='flex justify-center'>
            <div class='w-32'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643001567/uploaded_resources/Belong-min_nqgzjz.png'
                alt='belong'
              />
            </div>
            <div class='flex'>
              <h1 class='mx-auto my-auto font-black text-white text-5xl'>
                BELONG
              </h1>
            </div>
          </div>
          {steps.emailRequired
            ? renderEmailForm()
            : steps.codeRequested
            ? renderConfirmationForm()
            : steps.passwordChanged
            ? renderSuccessScreen()
            : null}
        </div>

        <div class='flex absolute bottom-0 w-full'>
          <div class='mx-auto p-1 pb-0 bg-primary rounded-tl-md rounded-tr-md cursor-default'>
            <p class='font-semibold text-xs text-white pl-2 pr-2'>
              An Ingenious Faces Marketplace
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResestPassword;
