import React, { useState } from 'react';
import ProfileDetails from './ProfileDetails';
import ProfileExp from './ProfileExp';
import axios from 'axios';

import { API_URL } from '../API_LINKS';

const CompleteProfile = (props) => {
  const [loading, setLoading] = useState(false);
  // const certification = true
  const UPLOAD_URL = 'https://belong-api.herokuapp.com/api/upload';
  const user = props.userData;
  let userInfo = `Hello, I'm ${user.name} from ${user.country} based at ${user.city}.
  I’m undergoing training in ${user.course} at ${user.university} and
  will be graduating in ${user.graduationYear}.`;

  if (user.graduationYear <= 2022) {
    userInfo = `Hello, I'm ${user.name} from ${user.country} based at ${user.city}.
    I have completed training in ${user.course} at ${user.university} and
    graduated in ${user.graduationYear}.`;
  }

  let externalAssessments = [];

  user.enrollments.forEach((enrollment) => {
    if (enrollment.metadata) {
      if (enrollment.metadata.externalAssessments) {
        externalAssessments.push(enrollment.metadata.externalAssessments);
      }
    }
  });

  const downloadCertificate = async () => {
    setLoading(true);
    const result = await axios.get(API_URL + '/assessment/assessment-pdf', {
      params: {
        userId: user._id,
      },
    });
    window.open(`${UPLOAD_URL}/${result.data.certificateUrl}`);
    props.fetchUser(user.cognitoId);
    setLoading(false);
  };
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  const masterclasslinkedinUrl =
    user.masterclassAssessment && user.masterclassAssessment.certificateUrl;
  const linkedinShareUrl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Belong Assessment certificate&organizationId=14375686&issueYear=${year}&issueMonth=${month}&certUrl=https://belong-api.herokuapp.com/api/upload/${masterclasslinkedinUrl}`;
  return (
    <div className='bg-white relative'>
      <div className='p-6'>
        <div className='flex justify-end invisible md:visible'>
          <button
            onClick={() => props.setProfileStatus(false)}
            className={`absolute bg-primary-light hover:bg-primary hover:text-gray-100 rounded-full px-6 py-1 text-sm border font-bold text-gray-500 shadow-lg md:-mt-3`}
          >
            Edit
          </button>
        </div>
        <div
          className={`${
            externalAssessments.length !== 0 ? 'md:items-start' : 'md:items-end'
          } flex flex-col items-center md:flex-row md:mt-3`}
        >
          {user.imageUrl ? (
            <img
              src={`${UPLOAD_URL}/${user.imageUrl}`}
              alt='profile'
              className='w-24 h-24 md:h-32 md:w-40  rounded-2xl shadow'
            />
          ) : null}
          <div className='md:grid md:grid-cols-4'>
            <ProfileDetails
              user={user}
              userInfo={userInfo}
              certification={externalAssessments}
            />
            {externalAssessments.length !== 0 ? (
              <ProfileExp user={user} UPLOAD_URL={UPLOAD_URL} />
            ) : null}
          </div>
        </div>
        <div className='flex justify-center visible md:invisible'>
          <button
            onClick={() => props.setProfileStatus(false)}
            className={`absolute bg-primary-light hover:bg-primary  rounded-full px-6 py-1 text-sm border font-bold text-gray-500 shadow-lg`}
          >
            Edit
          </button>
        </div>
      </div>
      <div className='flex flex-col md:flex-row mt-6 md:mt-0 border-2 border-l-0 border-r-0 border-b-0 w-full'>
        {externalAssessments.length !== 0 ? (
          <div className='py-6 px-8 border-2 border-t-0 border-l-0 border-b-0 border-b-2 md:border-b-0 border-r-0 md:border-r-2 w-full md:w-1/3'>
            <div className='flex mb-8'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1632399947/uploaded_resources/WhatsApp_Image_2021-09-23_at_5.25.50_PM_f40stu.jpg'
                alt='Score'
                className='w-10'
              />
              <p className='text-gray-500 text-sm pl-10 font-bold'>
                I Have A Certification in:
              </p>
            </div>
            <div className='bg-primary-light inline-block w-full text-xs font-bold text-gray-600 m-2 px-2 py-1 flex justify-between'>
              <div>
                <p className='mb-1'>
                  SPEAK English Test -{' '}
                  {externalAssessments[0].speak.speakScore.speakScore}/120
                </p>
                <p>
                  CEFR Level -{' '}
                  {externalAssessments[0].speak.speakScore.totalScore} -
                  Proficient Speaker
                </p>
              </div>
              <div className='flex items-center'>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1632399850/uploaded_resources/WhatsApp_Image_2021-09-23_at_5.26.31_PM_hmsrha.jpg'
                  alt='Score'
                  className='w-6'
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col border-2 border-t-0 border-l-0 border-b-2 md:border-b-0 border-r-0 md:border-r-2 py-6 px-8 text-sm font-bold w-full md:w-1/3'>
            <div className='flex mb-8'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643354919/uploaded_resources/Early_Talent_Icon_wo0m4c.png'
                alt='Score'
                className='w-10'
              />
              <div className='flex flex-col pl-10 '>
                {user.experience ? (
                  <p className='text-gray-500 mb-1'>
                    {user.experience} Month Experience{' '}
                  </p>
                ) : (
                  <p className='text-gray-500 mb-1'>No Work Experience</p>
                )}
                <p className=' text-primary '>Early Upskilled Talent</p>
              </div>
            </div>
            <div className='flex mb-8'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643354960/uploaded_resources/Work_Icon_qvhf1g.png'
                alt='Score'
                className='w-10'
              />
              <div className='flex flex-col px-10 '>
                <p className='text-gray-500 mb-1'>Worked With</p>
                <p className=' text-primary '>{user.previousCompany}</p>
              </div>
            </div>
            <div className='flex'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643354983/uploaded_resources/View_Resume_Icon_dl2cem.png'
                alt='Score'
                className='w-10'
              />
              <div className='flex flex-col px-10 '>
                <p className='text-gray-500 mb-1'>To Know More</p>
                <a
                  className=' text-primary '
                  href={`${UPLOAD_URL}/${user.resumeUrl}`}
                  download
                  target='_blank'
                  rel='noreferrer'
                >
                  View My Resume
                </a>
              </div>
            </div>
          </div>
        )}
        <div className='py-6 px-8 border-2 border-t-0 border-l-0 border-b-0 border-b-2 md:border-b-0 border-r-0 md:border-r-2 w-full md:w-1/3'>
          <div className='flex mb-8'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643355008/uploaded_resources/Skillset_Icon_hfc8hb.png'
              alt='Score'
              className='w-10'
            />
            <p className='text-gray-500 text-sm pl-10 font-bold'>
              My Skill Set Includes:
            </p>
          </div>
          <div>
            {user.areas.map((area, index) => {
              return (
                <div className='inline-flex flex-wrap' key={index}>
                  <div className='bg-primary-light inline-block text-xs font-bold text-gray-600 m-2 px-4 py-1 '>
                    {area}
                  </div>
                </div>
              );
            })}
          </div>

          <div className=' mt-6 bg-primary-light hover:bg-primary text-primary hover:text-white font-semibold px-4 py-2 text-center  md:text-lg rounded'>
            <button
              onClick={() => {
                downloadCertificate();
              }}
              disabled={loading}
            >
              {loading ? 'Downloading...' : 'Download Certificate'}
            </button>
          </div>
          {masterclasslinkedinUrl && (
            <div className='mt-6 text-center'>
              <a href={linkedinShareUrl} target='_blank' rel='noreferrer'>
                <input
                  alt='share'
                  type='image'
                  src='https://res.cloudinary.com/belong/image/upload/v1649941720/uploaded_resources/en_US_zdmxmg.png'
                />
              </a>
            </div>
          )}
        </div>
        <div className='px-8 py-6 w-full md:w-1/3'>
          <div className='flex mb-8'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643354878/uploaded_resources/Industry_Icon_qzwsj2.png'
              alt='Score'
              className='w-10'
            />
            <p className='text-gray-500 text-sm pl-10 font-bold'>
              Looking To Work In:
            </p>
          </div>
          <div>
            {user.sectors.map((sector, index) => {
              return (
                <div className='inline-flex flex-wrap' key={index}>
                  <div className='bg-primary-light m-2 px-4 py-1 font-bold text-gray-600 text-xs flex'>
                    {sector}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
