import React, { useState, useRef } from 'react';
import Assessment from './Assessment';
import Instruction from './Instruction';
import { AiFillClockCircle } from 'react-icons/ai';
import _ from 'lodash';

const Index = ({ setAssessmentActive, assessmentQuestion, assessmentId }) => {
  const [instruction, setInstruction] = useState(true);
  const [time, setTime] = useState({});
  const [endQuiz, setEndQuiz] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);

  let shuffleAssessmentQuestion = [];

  const localShuffleAssessmentQuestion = JSON.parse(
    localStorage.getItem('localShuffleAssessmentQuestion') || '[]'
  );

  if (localShuffleAssessmentQuestion.length) {
    shuffleAssessmentQuestion = localShuffleAssessmentQuestion;
  } else {
    const shuffleQuestion = _.shuffle(assessmentQuestion);
    localStorage.setItem(
      'localShuffleAssessmentQuestion',
      JSON.stringify(shuffleQuestion)
    );
    shuffleAssessmentQuestion = shuffleQuestion;
  }

  let interval = useRef(null);

  const totalQuestion = shuffleAssessmentQuestion
    .map((q) => q.questions.length)
    .reduce((a, b) => a + b, 0);

  const localCountDownTime = localStorage.getItem('localCountDownTime');
  const startTimer = () => {
    let countDownTime = Date.now() + 20 * 60 * 1000;

    if (localCountDownTime) {
      countDownTime = parseInt(localCountDownTime) + 20 * 60 * 1000;
    } else {
      localStorage.setItem('localCountDownTime', +Date.now());
    }
    interval = setInterval(() => {
      const now = new Date();
      const distance = countDownTime - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setAutoSubmit(true);
        setTime({ minutes: 0, seconds: 0 });
        if (distance < -10000) {
          setEndQuiz(true);
          setTimeout(true);
          clearInterval(interval);
        }
      } else {
        setTime({ minutes: minutes, seconds: seconds });
      }
    }, 1000);
  };
  return (
    <div>
      {instruction ? (
        <Instruction
          setInstruction={setInstruction}
          startTimer={startTimer}
          totalQuestion={totalQuestion}
          assessmentQuestion={shuffleAssessmentQuestion}
        />
      ) : endQuiz ? (
        <div className='text-center font-semibold mt-4'>
          Assessment Time finish
        </div>
      ) : (
        <div>
          <div className='flex justify-end text-lg items-center bg-gray-50 px-4 py-1 text-primary mb-2'>
            <AiFillClockCircle />
            <p className='ml-1 text-gray-600 text-base'>
              {' '}
              : {time.minutes} : {time.seconds}
            </p>
          </div>
          <Assessment
            setAssessmentActive={setAssessmentActive}
            autoSubmit={autoSubmit}
            assessmentQuestion={shuffleAssessmentQuestion}
            assessmentId={assessmentId}
          />
        </div>
      )}
    </div>
  );
};

export default Index;
