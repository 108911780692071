import { STORE_ASSESSMENT } from './assessmentTypes';

const INITIAL_STATE = {
  assessment: null,
};

const assessmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_ASSESSMENT:
      return { ...state, assessment: action.payload };
    default:
      return state;
  }
};

export default assessmentReducer;
