import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../API_LINKS';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';

const Preview = ({
  finalSubmission,
  autoSubmit,
  setAssessmentActive,
  assessmentId,
  user,
}) => {
  const [previewQuestion, setPreviewQuestion] = useState(
    finalSubmission.flat()
  );
  const [isLoading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  const submitQuestions = () => {
    setServerResponse('Submitting your responses');
    setLoading(true);
    axios
      .post(API_URL + '/assessment/user-submission', {
        responses: previewQuestion,
        userId: user._id,
        startTime: parseInt(localStorage.getItem('localCountDownTime')),
        finishTime: +Date.now(),
        assessmentype: 'masterclass',
        assessmentId: assessmentId,
      })
      .then((res) => {
        if (res.status === 200) {
          setServerResponse('Submission successful!, Generating report');
          localStorage.removeItem('localShuffleAssessmentQuestion');
          localStorage.removeItem('localCountDownTime');
          localStorage.removeItem('localfinalSubmission');
          localStorage.removeItem('localCurrentCategory');
          setAssessmentActive('Transition');
          setLoading(false);
        }
        //Show report from response
      })
      .catch((res) => {
        setLoading(false);
        setServerResponse('Could not submit, please try again.');
      });
  };

  useEffect(() => {
    if (autoSubmit) {
      submitQuestions();
    }
  }, [autoSubmit]);

  const AnswerInput = (inputValue, questionIndex) => {
    const data = previewQuestion[questionIndex];
    data.userInput = inputValue;
    let tempObj = [...previewQuestion];
    tempObj[questionIndex] = data;
    setPreviewQuestion(tempObj);
  };

  const markOption = (indexOfOptionSelectedByUser, questionIndex) => {
    const data = previewQuestion[questionIndex];
    const { maxSelect } = data;
    const selectedOptionsCount = data.options.filter(
      (el) => el.selected
    ).length;

    if (data.options[indexOfOptionSelectedByUser].hasOwnProperty('selected')) {
      delete data.options[indexOfOptionSelectedByUser].selected;
    } else if (selectedOptionsCount < maxSelect) {
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    } else if (selectedOptionsCount === maxSelect) {
      data.options.forEach((option) => {
        if (option.hasOwnProperty('selected')) {
          delete option.selected;
        }
      });
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    }

    let tempObj = [...previewQuestion];
    tempObj[questionIndex] = data;
    setPreviewQuestion(tempObj);
  };

  return (
    <div>
      <div>
        {previewQuestion.map((myQuestion, idx) => {
          const question = myQuestion.question;
          const options = myQuestion.options;
          const image = myQuestion.image || [];
          const isInputType = myQuestion.responseType === 'text';
          return (
            <div className='mt-8' key={idx}>
              <p className='mb-2 font-semibold text-gray-500 text-lg'>
                {idx + 1}. {question}
              </p>
              <div className='grid grid-flow-col auto-cols-fr gap-4'>
                {image.map((imageName) => {
                  return (
                    <img
                      key={imageName}
                      className='rounded shadow h-full'
                      src={imageName}
                      alt='Question'
                    />
                  );
                })}
              </div>
              <div className='space-y-4 bg-gray-50 p-6'>
                {isInputType ? (
                  <div>
                    <input
                      type='text'
                      placeholder='Your Answer'
                      className='p-2 rounded w-full md:w-1/2'
                      value={myQuestion.userInput}
                      onChange={(e) => {
                        AnswerInput(e.target.value, idx);
                      }}
                    />
                  </div>
                ) : (
                  options.map((option, index) => {
                    return (
                      <div
                        key={option._id}
                        onClick={() => {
                          markOption(index, idx);
                        }}
                        className={classNames(
                          {
                            'text-blue-500': option.selected === true,
                          },
                          'cursor-pointer p-2 flex items-center font-semibold text-gray-500'
                        )}
                      >
                        {option.selected ? (
                          <span className='pr-2'>
                            <BiRadioCircleMarked />
                          </span>
                        ) : (
                          <span className='pr-2'>
                            <BiRadioCircle />
                          </span>
                        )}

                        {option.option}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='text-center mt-4'>
        <button
          className='bg-primary-light hover:bg-primary px-4 py-2 text-2xl font-semibold'
          onClick={() => submitQuestions()}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps)(Preview);
