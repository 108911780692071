import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn, signOut, fetchUser } from '../redux';
import Tour from 'reactour';
import ReportPreview from './ReportPreview';
import Interests from './Interests';
import Details from './Details';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Link } from 'react-router-dom';
import CompleteProfile from './CompleteProfile';

const Index = (props) => {
  const user = props.userData;
  const [profileStatus, setProfileStatus] = useState(false);

  useEffect(() => {
    if (
      user.name &&
      user.email &&
      user.contactNumber &&
      user.country &&
      user.city &&
      user.university &&
      user.course &&
      user.graduationYear &&
      user.areas.length &&
      user.sectors.length
    ) {
      setProfileStatus(true);
    }
  }, [
    user.name,
    user.email,
    user.contactNumber,
    user.country,
    user.city,
    user.university,
    user.course,
    user.graduationYear,
    user.areas.length,
    user.sectors.length,
  ]);

  const [saving, setSaving] = useState(false);

  const [isTourOpen, setIsTourOpen] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  // const openTour = () => {
  //   setIsTourOpen(true);
  // };

  const tourConfig = [
    {
      selector: '[data-tut="profile-page"]',
      content:
        'Before you enrol or apply to any program on Belong we require you to complete your profile.',
    },
    {
      selector: '[data-tut="employability"]',
      content: () => (
        <div>
          <p>
            This assessment gives you a score and review on 4 key employability
            skills.
          </p>
          <div className='flex justify-center mt-4'>
            <Link to='/'>
              <button className='rounded-full text-xs md:text-sm px-4 md:px-8 py-2 bg-primary-light hover:bg-primary font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'>
                What's More?
              </button>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  //Change this
  // if (!AUTH_STATUS) {
  if (!true) {
    return <Navigate to='/' />;
  } else {
    return (
      <>
        <Tour
          steps={tourConfig}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          rounded={5}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        />
        {props.userData.loading ? (
          <h2>Loading...</h2>
        ) : props.userData.error ? (
          <h2>{props.userData.error}</h2>
        ) : (
          <div className='pt-4 lg:px-8 md:pt-8 select-none'>
            <div className='grid grid-cols-10 '>
              <div className='col-span-10 md:col-span-1 pt-4'></div>
              <div className='col-span-10 md:col-span-8 pt-4 bg-gray-100 rounded-2xl'>
                <div className='p-4'>
                  {!profileStatus ? (
                    <>
                      <div className='flex justify-end'>
                        <button
                          onClick={() => setSaving(true)}
                          className=' bg-primary-light hover:bg-primary hover:text-gray-100 rounded-full px-6 py-1 text-sm border font-bold text-gray-500'
                        >
                          Save
                        </button>
                      </div>
                      <div className='flex flex-col md:flex-row md:justify-between w-full border-b-2'>
                        <div className='bg-gray-100 border-0 md:border-r-2 px-4 pr-4 py-6 w-full md:w-1/3'>
                          <Details saving={saving} setSaving={setSaving} />
                        </div>
                        <div className='w-full'>
                          <div className='relative'>
                            <Interests saving={saving} setSaving={setSaving} />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className=''>
                      <CompleteProfile
                        userData={props.userData}
                        setProfileStatus={setProfileStatus}
                        fetchUser={props.fetchUser}
                      />
                    </div>
                  )}

                  <div className='grid grid-cols-12'>
                    <div className='col-span-12 bg-gray-100 h-full  py-4'></div>
                    <div className='col-span-2 hidden'>
                      <ReportPreview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentAuthStatus: state.auth.authStatus,
    userData: state.user.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    fetchUser: (cognitoId) => dispatch(fetchUser(cognitoId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
