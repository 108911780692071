import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { FaArrowRight } from 'react-icons/fa';

import PartnerSignupForm from './PartnerSignupForm';
import { LoadingSpinner } from './layout/LoadingSpinner';
import LoginToggle from './auth/LoginToggle';
import LoginForm from './auth/LoginForm';

export const PartnerSignup = ({ partner, partnerCode, code }) => {
  const [authLoading, setauthLoading] = useState(true);
  const [signupStatus, setSignupStatus] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setauthLoading(false);
        setSignupStatus(true);
      })
      .catch((err) => {
        setauthLoading(false);
        setSignupStatus(false);
      });
  }, []);

  const listStyle = 'text-white text-xl font-semibold';

  return authLoading ? (
    <LoadingSpinner />
  ) : signupStatus ? (
    <Redirect to={`/assessment/${code}`} />
  ) : partnerCode ? (
    <>
      <div
        className='md:h-screen grid grid-flow-col grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-2'
        style={{
          backgroundImage: `url(
              'https://res.cloudinary.com/belong/image/upload/v1628848245/uploaded_resources/Desktop_p01vyu.png'
            )`,
          backgroundSize: `cover`,
          backgroundPosition: '-100px -100px',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div>
          <div className='p-4 md:p-16 flex justify-between md:justify-start space-x-8 md:space-x-16 items-center'>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
                alt='Belong'
                className='hidden sm:block w-40'
              />
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643351800/uploaded_resources/logo-small_jt2fqr.png'
                alt='Belong'
                className='sm:hidden h-16'
              />
            </div>

            <div>
              <img
                src={partner.logoUrl}
                alt='Partner'
                className='hidden sm:block w-40'
              />
              <img
                src={partner.logoUrl}
                alt='Partner'
                className='sm:hidden h-20'
              />
            </div>
          </div>

          <div className='p-4 md:px-16 space-y-8 '>
            <p className='text-primary text-4xl font-bold'>
              Global Employability Masterclass
            </p>
            <p className='text-xl text-gray-400 font-semibold md:w-3/4'>
              Unlock your Global Employability with Belong!
            </p>
            <a href='#form'>
              <div className='flex text-2xl text-primary font-semibold space-x-12 items-center'>
                <p>Register Now</p>
                <p>
                  <FaArrowRight />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className='bg-primary -mt-80 md:-mt-32 px-4 py-16 md:p-16 overflow-hidden'>
        <p className='text-2xl mb-4 text-white font-semibold'>
          Explore the 3 A’s to build your Global profile
        </p>
        <ul className='space-y-8 px-4 md:px-0 list-disc md:w-1/3 mt-8 md:mt-0'>
          <li className={listStyle}>
            <span className='text-2xl'>Acquire</span> 21st century dynamic
            skills
          </li>
          <li className={listStyle}>
            <span className='text-2xl'>Access</span> Global Network of
            Internships
          </li>
          <li className={listStyle}>
            <span className='text-2xl'>Accelerate</span> your career through
            Employability Assessment
          </li>
        </ul>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1628848245/uploaded_resources/Group_2_xbr94l.png'
          alt='Laptop'
        />
        <div id='form' className='lg:w-2/5 lg:absolute top-80 right-16'>
          {showLogin ? (
            <LoginForm />
          ) : (
            <PartnerSignupForm partner={partner} partnerCode={partnerCode} />
          )}
          <LoginToggle showLogin={showLogin} setShowLogin={setShowLogin} />
        </div>
      </div>
    </>
  ) : (
    'Please check the link'
  );
};
