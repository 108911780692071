import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import Dropzone from 'react-dropzone';
import { GraduationYears } from '../layout/constants';

import { signIn, signOut, fetchUser } from '../redux';
import { API_URL, UPLOAD_OR_DOWNLOAD_URL } from '../API_LINKS';

const Details = (props) => {
  const { setSaving } = props;
  async function deleteFile(fileKey, fieldToUpdate) {
    const result = await axios.delete(
      `${UPLOAD_OR_DOWNLOAD_URL}/${fileKey}/${fieldToUpdate}`
    );
    if (result.data === 'success') {
      props.fetchUser(props.user.cognitoId);
    } else {
      alert('Could not delete file, please try again.');
    }
  }
  async function uploadFile(
    files,
    fieldToUpdate,
    fileFormat,
    cognitoId = props.user.cognitoId
  ) {
    if (cognitoId) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('id', cognitoId);
      formData.append('fieldToUpdate', fieldToUpdate);
      formData.append('fileFormat', fileFormat);

      const result = await axios.post(UPLOAD_OR_DOWNLOAD_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (result.data.status === 'success') {
        setSaving(true);
      }
      if (result.data.status === 'error') {
        alert(result.data.message);
      }
    } else {
      alert('Could not upload file, please try again.');
    }
  }

  const [detailsUpdating, setDetailsUpdating] = useState(false);
  const [fileUploadAreaVisible, setFileUploadAreaVisible] = useState(false);
  // const [sentVerification, setSentVerification] = useState(false);
  // const [bannerMessage, setBannerMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      name: props.user.name ? props.user.name : '',
      email: props.user.email ? props.user.email : '',
      contact: props.user.contactNumber ? props.user.contactNumber : '',
      city: props.user.city ? props.user.city : '',
      country: props.user.country ? props.user.country : '',
      university: props.user.university ? props.user.university : '',
      course: props.user.course ? props.user.course : '',
      gradYear: props.user.graduationYear ? props.user.graduationYear : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required').min(2),
      // email: Yup.string().email('Invalid email address').required('Required'),
    }),
  });

  const cId = props.user.cognitoId;
  const fetchUser = props.fetchUser;

  const updateDetails = useCallback(() => {
    setDetailsUpdating(true);
    if (!detailsUpdating) {
      axios
        .patch(API_URL + '/user', {
          cognitoId: cId,
          updateType: 'profile',
          ...formik.values,
        })
        .then((res) => {
          fetchUser(cId);
          setDetailsUpdating(false);
        })
        .catch((res) => {
          setDetailsUpdating(false);
        });
    }
  }, [cId, fetchUser, detailsUpdating, formik.values]);

  // const sendVerification = async () => {
  //   if (!sentVerification) {
  //     await axios
  //       .post(API_URL + '/verify/email', {
  //         cognitoId: props.user.cognitoId,
  //       })
  //       .then((res) => {
  //         setSentVerification(true);
  //       })
  //       .catch((res) => {
  //         console.log('Error');
  //       });
  //   }
  // };
  // useEffect(() => {
  //   sentVerification
  //     ? setBannerMessage('Mail sent for verification.')
  //     : setBannerMessage('');
  // }, [sentVerification]);

  useEffect(() => {
    if (props.saving) {
      updateDetails();
    }
  }, [props.saving, updateDetails]);

  const inputStyle =
    'border-0 outline-none  border-b-2 border-gray-200 bg-transparent text-primary';
  return (
    <>
      {props.user.loading || detailsUpdating ? (
        ''
      ) : (
        <div className='relative'>
          <p></p>
          <div className='flex mb-8'>
            <p className='font-bold text-gray-700'>1. About Me</p>
            <p className='border-b-2 border-gray-200 ml-2 flex-auto mr-4'></p>
          </div>
          <div className='flex flex-col space-y-2 text-sm text-gray-600'>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Name</p>
              <input
                type='text'
                name='name'
                className={inputStyle}
                placeholder='Your Name'
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div>{formik.errors.name}</div>
              ) : null}
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Email</p>
              <p className='ml-1'>{props.user.email}</p>
              {/* <input
                type='text'
                name='email'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.email}
              /> */}
            </div>
            {/* {!props.user.verification.email.status && (
              <>
                <p className='flex justify-end text-gray-500 text-xs'>
                  {bannerMessage}
                </p>
                <button
                  className={classNames({
                    'py-2 px-4 bg-white rounded shadow text-xs text-gray-400 font-semibold ml-2':
                      !sentVerification,
                    hidden: sentVerification,
                  })}
                  onClick={sendVerification}
                >
                  Verify your email
                </button>
              </>
            )} */}
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Contact Number</p>
              <input
                type='text'
                name='contact'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.contact}
              />
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>City</p>
              <input
                type='text'
                name='city'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.city}
              />
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Country</p>
              <input
                type='text'
                name='country'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.country}
              />
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>University</p>
              <input
                type='text'
                name='university'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.university}
              />
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Course</p>
              <input
                type='text'
                name='course'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.course}
              />
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Gradution Year</p>
              <select
                name='gradYear'
                className={inputStyle}
                onChange={formik.handleChange}
                value={formik.values.gradYear}
              >
                <option value='Not Applicable'>Not Applicable </option>
                {GraduationYears.map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Profile Image</p>
              {props.user.imageUrl ? (
                <div className='flex items-center'>
                  <a
                    href={`${UPLOAD_OR_DOWNLOAD_URL}/${props.user.imageUrl}`}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <p className=' text-primary cursor-pointer mr-4'>
                      Image uploaded
                    </p>
                  </a>
                  <FaTrash
                    className=' cursor-pointer text-gray-400 hover:text-red-400'
                    onClick={() => {
                      deleteFile(props.user.imageUrl, 'PROFILE_IMAGE');
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    className='text-right text-primary mr-4 cursor-pointer'
                    onClick={() => {
                      setFileUploadAreaVisible(!fileUploadAreaVisible);
                    }}
                  >
                    Upload Profile Image
                  </p>

                  <div className={`${!fileUploadAreaVisible && 'hidden'}`}>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadFile(acceptedFiles, 'PROFILE_IMAGE', 'pdf')
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className='cursor-pointer border-4 border-dashed p-8 text-center text-lg my-4'>
                              Drag your file here, or
                              <span className='text-primary'> click </span> to
                              select files
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentAuthStatus: state.auth.authStatus, user: state.user.user };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    fetchUser: (cognitoId) => dispatch(fetchUser(cognitoId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
