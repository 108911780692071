import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import { fetchUser, addUser, signIn } from './redux';

const PartnerSignupForm = ({ partner, partnerCode }) => {
  const inputStyle = 'shadow p-2 w-full border-gray-200 border rounded';
  const labelStyle = 'text-gray-600 mb-2 inline-block';
  const [serverResponse, setServerResponse] = useState();
  const [signupSuccess, setSignupSuccess] = useState(false);

  const partnerId = partner._id;
  const courses = partner.courses;
  const university = partner.university;
  const isEvent = partner.isEvent;
  const showCourses = partner.showCourses;

  async function awsSignUp(
    email,
    password,
    name,
    partnerId,
    course,
    year,
    university,
    setSubmitting
  ) {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        email: email,
        // other custom attributes
        'custom:partnerId': partnerId,
        'custom:course': course,
        'custom:course_year': year,
        'custom:university': university,
      },
    })
      .then((res) => {
        setSignupSuccess(true);
        setSubmitting(false);
        setServerResponse('');
      })
      .catch((error) => {
        setServerResponse(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return signupSuccess ? (
    <Redirect to='/assessment' />
  ) : (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
        confirmpassword: '',
        course: courses ? courses[0] : 'Business',
        year:
          partnerId === '6170230a5a244d0023bd1d5c'
            ? ''
            : partnerCode === 'mitsde'
            ? '2019'
            : '2021',
        partnerId: partnerId,
        university: university ? university[0] : null,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, 'Must be 3 characters or more')
          .max(100, 'Must be 100 characters or less')
          .required('Please enter your Name'),
        email: Yup.string().email().required('Please enter your Email'),
        password: Yup.string()
          .required('Please enter your Password')
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character'
          ),
        confirmpassword: Yup.string()
          .required('Please confirm your Password')
          .when('password', {
            is: (password) => (password && password.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              "Password doesn't match"
            ),
          }),
        course: Yup.string(),
        year: Yup.number(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setServerResponse('Creating your account...');
        awsSignUp(
          values.email,
          values.password,
          values.name,
          values.partnerId,
          values.course,
          values.year,
          values.university,
          setSubmitting
        );
      }}
    >
      <Form className='bg-white space-y-8 p-4 md:p-8 shadow-lg rounded'>
        <div className='grid grid-flow-auto grid-cols-1 sm:grid-cols-2 gap-4'>
          <div>
            <label htmlFor='name' className={labelStyle}>
              Name
            </label>
            <Field
              name='name'
              type='text'
              placeholder='Name'
              className={inputStyle}
            />
            <ErrorMessage name='name' />
          </div>

          <div>
            <label htmlFor='email' className={labelStyle}>
              Email
            </label>
            <Field
              name='email'
              type='email'
              placeholder='Email'
              className={inputStyle}
            />
            <ErrorMessage name='email' />
          </div>
        </div>

        <div className='grid grid-flow-auto grid-cols-2 gap-4'>
          <div>
            <label htmlFor='password' className={labelStyle}>
              Password
            </label>
            <Field
              name='password'
              type='password'
              placeholder='Password'
              className={inputStyle}
            />
            <ErrorMessage name='password' />
          </div>
          <div>
            <label htmlFor='confirmpassword' className={labelStyle}>
              Confirm Password
            </label>
            <Field
              name='confirmpassword'
              type='password'
              placeholder='Confirm Password'
              className={inputStyle}
            />
            <ErrorMessage name='confirmpassword' />
          </div>
        </div>

        <div className='grid grid-flow-auto grid-cols-1 sm:grid-cols-2 gap-4'>
          <div className={`${!showCourses && 'hidden'}`}>
            <label htmlFor='course' className={labelStyle}>
              Course
            </label>
            <Field
              name='course'
              as='select'
              placeholder='Course'
              className={inputStyle}
            >
              {courses && courses.length !== 0 ? (
                courses.map((course, index) => {
                  return (
                    <option key={index} value={course}>
                      {course}
                    </option>
                  );
                })
              ) : (
                <>
                  <option value='Business'>Business</option>
                  <option value='Computer Science and Engineering (CSE)'>
                    Computer Science and Engineering (CSE)
                  </option>
                  <option value='Mechanical Engineering (ME)'>
                    Mechanical Engineering (ME)
                  </option>
                  <option value='Biotechnology (BT)'>Biotechnology (BT)</option>
                  <option value='Electronics and Communication Engineering (ECE)'>
                    Electronics and Communication Engineering (ECE)
                  </option>
                  <option value='Automobile Engineering (AE)'>
                    Automobile Engineering (AE)
                  </option>
                  <option value='Food Technology'>Food Technology</option>
                  <option value='Electrical Engineering (EE)'>
                    Electrical Engineering (EE)
                  </option>
                  <option value='Civil Engineering (CE)'>
                    Civil Engineering (CE)
                  </option>
                  <option value='Management'>Management</option>
                  <option value='Computer Applications and IT'>
                    Computer Applications and IT
                  </option>
                  <option value='Commerce'>Commerce</option>
                  <option value='Pharmaceutical Sciences'>
                    Pharmaceutical Sciences
                  </option>
                  <option value='Ayurvedic Pharmaceutical Sciences'>
                    Ayurvedic Pharmaceutical Sciences
                  </option>
                  <option value='Agriculture'>Agriculture</option>

                  <option value='Economics'>Economics</option>
                  <option value='Physiotherapy'>Physiotherapy</option>

                  <option value='Food Technology'>Food Technology</option>
                  <option value='Law'>Law</option>
                  <option value='MLT/ Paramedical Sciences/ Embryology'>
                    MLT/ Paramedical Sciences/ Embryology
                  </option>
                  <option value='Nutrition and Dietetics'>
                    Nutrition and Dietetics
                  </option>
                  <option value='Hotel Management and Tourism'>
                    Hotel Management and Tourism
                  </option>
                  <option value='Psychology'>Psychology</option>
                  <option value='Biotechnology'>Biotechnology</option>
                  <option value='Architecture'>Architecture</option>
                  <option value='Sociology'>Sociology</option>
                  <option value='Microbiology'>Microbiology</option>
                  <option value='Planning'>Planning</option>
                  <option value='History'>History</option>
                  <option value='Forensic Sciences'>Forensic Sciences</option>
                  <option value='Design'>Design</option>
                  <option value='Fine Arts'>Fine Arts</option>
                  <option value='Sciences'>Sciences</option>
                  <option value='Interior and Furniture Design'>
                    Interior and Furniture Design
                  </option>
                  <option value='Political Science'>Political Science</option>
                  <option value='Botany'>Botany</option>
                  <option value='Product and Industrial Design'>
                    Product and Industrial Design
                  </option>
                  <option value='Public Administration'>
                    Public Administration
                  </option>
                  <option value='Zoology'>Zoology</option>
                  <option value='Fashion Design'>Fashion Design</option>
                  <option value='Geography'>Geography</option>
                  <option value='Physics'>Physics</option>
                  <option value='Multimedia and Animation'>
                    Multimedia and Animation
                  </option>
                  <option value='English and Foreign Languages'>
                    English and Foreign Languages
                  </option>
                  <option value='Chemistry'>Chemistry</option>
                  <option value='Journalism and Film Production'>
                    Journalism and Film Production
                  </option>
                  <option value='Indian Languages'>Indian Languages</option>
                  <option value='Mathematics'>Mathematics</option>
                  <option value='Performing Arts'>Performing Arts</option>
                  <option value='Physical Education'>Physical Education</option>
                  <option value='Library Science'>Library Science</option>
                  <option value='Education'>Education</option>
                </>
              )}
            </Field>
            <ErrorMessage name='course' />
          </div>
          {!(partnerId === '6170230a5a244d0023bd1d5c') && (
            <div>
              <label htmlFor='year' className={labelStyle}>
                {partnerId === '61b84fd22130e1002351dc38'
                  ? 'Post Graduation Passout Year'
                  : 'Graduation Year'}
              </label>
              <Field name='year' as='select' className={`w-full ${inputStyle}`}>
                {partnerCode === 'mitsde' && (
                  <>
                    <option value='2019'>2019</option>
                    <option value='2020'>2020</option>
                  </>
                )}
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
              </Field>
              <ErrorMessage name='year' />
            </div>
          )}
          <div className='col-span-2'>
            {isEvent && university && university.length && (
              <div>
                <label htmlFor='year' className={labelStyle}>
                  University
                </label>
                <Field
                  name='university'
                  as='select'
                  className={`w-full ${inputStyle}`}
                >
                  {university.map((course, index) => {
                    return (
                      <option key={index} value={course}>
                        {course}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage name='year' />
              </div>
            )}
          </div>
        </div>

        <div className='text-center'>
          <button
            type='submit'
            className='px-4 py-2 shadow bg-primary-light rounded'
          >
            Submit
          </button>
          <div className='my-4'>{serverResponse}</div>
        </div>
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuthStatus: state.auth.authStatus,
    userDetails: state.user,
    newUser: state.newUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    fetchUser: (cognitoId) => dispatch(fetchUser(cognitoId)),
    addUser: (userInfo) => dispatch(addUser(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignupForm);
