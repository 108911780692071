import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { connect } from 'react-redux';

import { signIn, signOut, fetchUser } from './redux';
import { LoadingSpinner } from './layout/LoadingSpinner';
import ResestPassword from './auth/ResetPassword';
import { PartnerSignup } from './PartnerSignup';
import Login from './auth/Login';
import Event from './event';
import Masterclass from './masterclass';
import FinalScreen from './finalscreen';
import EnterCode from './masterclass/EnterCode';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_h5OexVjb0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '20738icdfn538kieuetqrajoj2',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'belong.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dashboard.belong.education',
      redirectSignOut: 'https://dashboard.belong.education',
      clientId: '20738icdfn538kieuetqrajoj2',
      responseType: 'token',
    },
  },
});

const Index = ({
  currentAuthStatus,
  signIn,
  signOut,
  userDetails,
  newUser,
  fetchUser,
}) => {
  const [loading, setLoading] = useState(true);
  // Auth.signOut({ global: true });

  useEffect(() => {
    if (currentAuthStatus) {
      if (!userDetails.loading && !newUser.loading) {
        //if both loadings are finished
        if (userDetails.user.cognitoId) {
          setLoading(false);
        } else if (
          newUser.user.cognitoId !== '' &&
          newUser.user.cognitoId !== undefined
        ) {
          if (!userDetails.loading) {
            fetchUser(newUser.user.cognitoId);
          }
        }
      }
    } else {
      setLoading(false);
    }
  }, [
    currentAuthStatus,
    signOut,
    userDetails.user.cognitoId,
    userDetails.loading,
    newUser.loading,
    newUser.user.cognitoId,
    fetchUser,
    userDetails.user.email,
  ]);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        currentAuthStatus ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  if (loading) {
    return <LoadingSpinner loading={loading} />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute path='/profile' component={Event} />
          <PrivateRoute path='/assessment/:code' component={Event} />
          <Route path='/signup/:partnerCode' component={PartnerSignup} exact />
          <Route path='/roles' component={FinalScreen} />
          <Route path='/login' component={Login} />
          <Route path='/resetPassword' component={ResestPassword} />
          <Route path='/:code' exact component={Masterclass} />
          <Route path='/' exact component={EnterCode} />
        </Switch>
      </BrowserRouter>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    currentAuthStatus: state.auth.authStatus,
    userDetails: state.user,
    newUser: state.newUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    fetchUser: (id) => dispatch(fetchUser(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);

//Create user profile on signup
//store user profile object in store, this can have access control ROLE as well
//Add content in database
//Store content in redux store
//Add functionality to enroll for course
//Add functionality to do an ETP
//Add functioality of profile and restrictions
//Add payment functionality
//Emails
