import React from 'react'

const ProfileDetails = (props) => {
  return (
    <>
      <div className= 'flex flex-col justify-end text-center mt-2'>
        <p className='text-gray-500 tracking-wide mt-2 md:mt-0 font-semibold'>
          {props.user.country}
        </p>
        <p className='text-gray-600 tracking-wider text-lg font-bold'>
          {props.user.name}
        </p>
      </div>
      <div className = 'md:col-span-3'>
        <p className='text-xs md:text-sm italic font-bold text-gray-700 text-center md:text-left'>
          <span className='text-xl md:text-3xl'>"</span>
          {props.userInfo}
          <span className='text-xl md:text-3xl'>"</span>
        </p>
      </div>
    </>
  )
}

export default ProfileDetails
