import axios from 'axios';
import { API_URL } from '../../API_LINKS';
import {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
} from './userTypes';

export const addUser = (userInfo) => {
  return (dispatch) => {
    dispatch(addUserRequest());

    axios
      .post(API_URL + '/user', userInfo)
      .then((response) => {
        // response.data is the user
        const user = response.data;
        dispatch(addUserSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(addUserFailure(error.message));
      });
  };
};

export const addUserRequest = () => {
  return {
    type: ADD_USER_REQUEST,
  };
};

export const addUserSuccess = (user) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: user,
  };
};

export const addUserFailure = (error) => {
  return {
    type: ADD_USER_FAILURE,
    payload: error,
  };
};
