import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';

const CongratsPrograms = ({ name }) => {
  return (
    <div className='text-center text-lg'>
      <div>
        <p className='text-primary text-xl font-bold'>Congratulations!</p>
        <div>
          <BsFillCheckCircleFill className='text-green-700 text-3xl mx-auto my-4' />
        </div>
        <p>
          You have download & shared your Employability Certificate & Score
          <p className='my-4'>
            <span className='font-semibold'>{name}</span>, want to increase your
            chance of getting hired?
          </p>
          <div>
            <p className='text-primary font-semibold'>Belong Resume Booster</p>
            <a
              href='http://localhost:3001/jobs'
              target='_blank'
              rel='noreferrer noopener'
            >
              <button className='text-white bg-primary px-2 rounded shadow mt-2'>
                Register Now*
              </button>
            </a>
          </div>
        </p>
      </div>
    </div>
  );
};

export default CongratsPrograms;
