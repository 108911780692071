import React, { useState, useRef, useEffect, useCallback } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';
import axios from 'axios';
// import { Document, Page } from '@react-pdf/renderer';
import { FaTrash } from 'react-icons/fa';
import { fetchUser } from '../redux';
import { API_URL, UPLOAD_OR_DOWNLOAD_URL } from '../API_LINKS';
import { LoadingSpinner } from '../layout/LoadingSpinner';

const Interests = (props) => {
  const { setSaving } = props;
  const [sector, setSector] = useState([]);
  const [area, setArea] = useState([]);
  const style = {
    chips: {
      background: '#10ADB9',
    },
    searchBox: {
      border: 'none',
      borderBottom: '1px solid #10ADB9',
      borderRadius: '0px',
    },
    highlightOption: {
      background: 'red',
      color: '#ffffff',
    },
  };

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/sector');
      setSector(result.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/area');
      setArea(result.data);
    })();
  }, []);

  async function deleteFile(fileKey, fieldToUpdate) {
    const result = await axios.delete(
      `${UPLOAD_OR_DOWNLOAD_URL}/${fileKey}/${fieldToUpdate}`
    );
    if (result.data === 'success') {
      props.fetchUser(props.user.cognitoId);
    } else {
      alert('Could not delete file, please try again.');
    }
  }
  async function uploadFile(
    files,
    fieldToUpdate,
    fileFormat,
    cognitoId = props.user.cognitoId
  ) {
    if (cognitoId) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('id', cognitoId);
      formData.append('fieldToUpdate', fieldToUpdate);
      formData.append('fileFormat', fileFormat);

      const result = await axios.post(UPLOAD_OR_DOWNLOAD_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (result.data.status === 'success') {
        setSaving(true);
        props.fetchUser(cognitoId);
      }
      if (result.data.status === 'error') {
        alert(result.data.message);
      }
    } else {
      alert('Could not upload file, please try again.');
    }
  }

  const formikRef = useRef();
  const [detailsUpdating, setDetailsUpdating] = useState(false);

  const cId = props.user.cognitoId;
  const fetchUser = props.fetchUser;
  const updateDetails = useCallback(() => {
    setDetailsUpdating(true);
    if (!detailsUpdating) {
      axios
        .patch(API_URL + '/user', {
          cognitoId: cId,
          updateType: 'interest',
          ...formikRef.current.values,
        })
        .then((res) => {
          fetchUser(cId);
          setDetailsUpdating(false);
        })
        .catch((res) => {
          setDetailsUpdating(false);
        });
    }
  }, [cId, fetchUser, detailsUpdating]);

  useEffect(() => {
    if (props.saving) {
      updateDetails();
    }
  }, [props.saving, updateDetails]);

  const areasOfInterest = area.map((area) => {
    return area.title;
  });

  const sectorsOfInterest = sector.map((sector) => {
    return sector.title;
  });

  return (
    <>
      {props.user.loading || detailsUpdating ? (
        <LoadingSpinner />
      ) : (
        <div className='bg-gray-100 px-4 py-6'>
          <div className='flex mb-6 relative'>
            <p className='font-bold text-gray-700'>
              2. My Interests & Experience
            </p>
            <p className='border-b-2 border-gray-200 ml-2 w-32'></p>
          </div>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className=''>
              <Formik
                // enableReinitialize={false}
                innerRef={formikRef}
                initialValues={{
                  areas: props.user.areas ? props.user.areas : '',
                  sectors: props.user.sectors ? props.user.sectors : '',
                  interestComments: props.user.interestComments
                    ? props.user.interestComments
                    : '',
                  experience: props.user.experience
                    ? props.user.experience
                    : '',
                  previousCompany: props.user.previousCompany
                    ? props.user.previousCompany
                    : '',
                  linkedInUrl: props.user.linkedInUrl || '',
                }}
              >
                {(props) => {
                  const { values } = props;
                  return (
                    <div className='flex flex-col md:flex-row justify-between text-sm text-gray-600'>
                      <div className='pr-6'>
                        <FieldArray
                          name='areas'
                          render={(arrayHelpers) => (
                            <div className='text-xs font-semibold'>
                              <p className='mb-4 text-sm '>
                                Areas of Interest:
                              </p>

                              <Multiselect
                                isObject={false}
                                options={areasOfInterest}
                                selectedValues={values.areas}
                                displayValue={areasOfInterest}
                                onSelect={(selectedList, selectedItem) => {
                                  arrayHelpers.push(selectedItem);
                                }}
                                onRemove={(selectedList, removedItem) => {
                                  const idx = values.areas.indexOf(removedItem);
                                  arrayHelpers.remove(idx);
                                }}
                                style={style}
                              />
                            </div>
                          )}
                        />
                        <div className='mt-2'>
                          <p>Any additional interests</p>
                          <Field
                            type='text'
                            className='border-0 outline-none mt-4 border-b-2 border-primary bg-transparent text-primary text-center'
                            name='interestComments'
                          />
                        </div>
                        <FieldArray
                          name='sectors'
                          render={(arrayHelpers) => (
                            <div className='text-xs font-semibold'>
                              <p className='mb-4 mt-6 text-sm '>
                                Sectors of Interest:
                              </p>

                              <Multiselect
                                isObject={false}
                                options={sectorsOfInterest}
                                selectedValues={values.sectors}
                                displayValue={sectorsOfInterest}
                                onSelect={(selectedList, selectedItem) => {
                                  arrayHelpers.push(selectedItem);
                                }}
                                onRemove={(selectedList, removedItem) => {
                                  const idx =
                                    values.sectors.indexOf(removedItem);
                                  arrayHelpers.remove(idx);
                                }}
                                style={style}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <div className='text-center px-6 font-semibold mt-6 md:mt-0'>
                        <div>
                          <p>Months of Work Experience</p>
                          <Field
                            type='number'
                            className='border-0 outline-none mt-4 border-b-2 border-gray-200 bg-transparent text-primary text-center'
                            name='experience'
                          />
                        </div>
                        <div className='mt-4'>
                          <p>Last Company Worked For</p>
                          <Field
                            type='text'
                            className='border-0 outline-none mt-4 border-b-2 border-gray-200 bg-transparent text-primary text-center'
                            name='previousCompany'
                          />
                        </div>
                        <div className='mt-4'>
                          <p>LinkedIn Url</p>
                          <Field
                            type='text'
                            className='border-0 outline-none mt-4 border-b-2 border-gray-200 bg-transparent text-primary text-center'
                            name='linkedInUrl'
                          />
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
            <div className='text-center pl-6 mt-6 md:mt-0'>
              {props.user.resumeUrl ? (
                <>
                  <div className='text-center'>
                    <div className='mb-4'>
                      <a
                        href={`${UPLOAD_OR_DOWNLOAD_URL}/${props.user.resumeUrl}`}
                        download
                        target='_blank'
                        rel='noreferrer'
                      >
                        <p className=' text-primary cursor-pointer mr-4'>
                          Resume
                        </p>
                      </a>
                      <FaTrash
                        className='mx-auto cursor-pointer text-gray-400 hover:text-red-400'
                        onClick={() => {
                          deleteFile(props.user.resumeUrl, 'RESUME');
                        }}
                      />
                    </div>
                    {/* <Document
                      file={`${UPLOAD_OR_DOWNLOAD_URL}/${props.user.resumeUrl}`}
                      renderTextLayer={false}
                      className='-mb-24 w-1/2 '
                    >
                      <Page pageNumber={1} renderTextLayer={false} />
                    </Document> */}
                  </div>
                </>
              ) : (
                <>
                  <p>Upload CV/Resume</p>

                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      uploadFile(acceptedFiles, 'RESUME', 'pdf')
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className='cursor-pointer border-4 border-dashed p-8 text-center text-lg my-4'>
                            Drag your file here, or
                            <span className='text-primary'> click </span> to
                            select files
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <p className='mt-4 text-sm'>Please upload a .pdf file</p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentAuthStatus: state.auth.authStatus, user: state.user.user };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (cognitoId) => dispatch(fetchUser(cognitoId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interests);
