import React from 'react';
import classNames from 'classnames';

const Nav = (props) => {
  const active = props.assessmentActive;
  return (
    <div className='flex  m-4 md:m-8'>
      <div>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
          alt='Belong'
          className='hidden sm:block w-40'
        />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1643351800/uploaded_resources/logo-small_jt2fqr.png'
          alt='Belong'
          className='sm:hidden h-16'
        />
      </div>
      <div className='flex items-center mx-auto gap-8 md:gap-32 lg:gap-64 text-center'>
        <div className={classNames({ 'font-bold': active })}>
          <p
            className={classNames(
              ' rounded-full justify-center items-center h-10 w-10 inline-flex shadow',
              { 'bg-primary': active },
              { 'bg-gray-100': !active }
            )}
          >
            1
          </p>
          <p>Assessment</p>
        </div>
        <div className={classNames({ 'font-bold': !active })}>
          <p
            className={classNames(
              ' rounded-full justify-center items-center h-10 w-10 inline-flex shadow',
              { 'bg-primary': !active },
              { 'bg-gray-100': active }
            )}
          >
            2
          </p>
          <p>Profile</p>
        </div>
      </div>
    </div>
  );
};

export default Nav;
