import React from 'react';
import { Link } from 'react-router-dom';

const Role = ({ roleTitle, roleType, logo, slug }) => {
  return (
    <div className='p-4 relative border-2 shadow-md rounded-lg text-center'>
      <p className='text-primary h-12 font-semibold'>{roleTitle}</p>
      <div className='my-4 font-semibold border-2 text-center bg-white rounded-lg flex flex-col justify-evenly h-12'>
        {roleType}
      </div>
      <div className='flex justify-between items-center gap-2'>
        <div>
          <Link
            to={`jobs/${slug}`}
            className='text-white bg-primary rounded-md py-3 px-2 font-semibold w-32 cursor-pointer'
          >
            View More
          </Link>
        </div>
        <img className='max-h-6 md:max-h-8' src={logo} alt='logo' />
      </div>
    </div>
  );
};

export default Role;
