import React, { useState } from 'react';
import Questions from './layout/Questions';

const CategoryAssessment = ({
  categoryQuestions,
  currentCategory,
  setCurrentCategory,
  finalSubmission,
  setFinalSubmation,
  setAssessmentActive,
  autoSubmit,
  assessmentId,
}) => {
  const [userSubmission, setUserSubmission] = useState(
    finalSubmission[currentCategory]
  );
  return (
    <div>
      <Questions
        timeGiven={categoryQuestions.timeAllowed}
        totalQuestions={categoryQuestions.questions.length}
        setUserSubmission={setUserSubmission}
        userSubmission={userSubmission}
        title={categoryQuestions.category}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        finalSubmission={finalSubmission}
        setFinalSubmation={setFinalSubmation}
        setAssessmentActive={setAssessmentActive}
        autoSubmit={autoSubmit}
        assessmentId={assessmentId}
      />
    </div>
  );
};

export default CategoryAssessment;
