import React from 'react';

const ProfileExp = (props) => {
  return (
    <div className='col-span-4 md:grid md:grid-cols-3 text-sm font-bold mt-3 md:mt-8 mb-2'>
      <div className='flex md:justify-center px-2 md:px-0 py-2 md:py-0'>
        <img src='/Early Talent Icon.png' alt='Score' className='h-10' />
        <div className='flex flex-col pl-8 '>
          <p className='text-gray-500 mb-1'>No Work Experience</p>
          <p className=' text-primary '>Early Upskilled Talent</p>
        </div>
      </div>
      <div className='flex md:justify-center px-2 md:px-0 py-2 md:py-0'>
        <img src='/Work Icon.png' alt='Score' className='h-10' />
        <div className='flex flex-col px-10 '>
          <p className='text-gray-500 mb-1'>Worked With</p>
          <p className=' text-primary '>{props.user.previousCompany}</p>
        </div>
      </div>
      <div className='flex md:justify-center px-2 md:px-0 py-2 md:py-0'>
        <img src='View Resume Icon.png' alt='Score' className='h-10' />
        <div className='flex flex-col px-10 '>
          <p className='text-gray-500 mb-1'>To Know More</p>
          <a
            className=' text-primary '
            href={`${props.UPLOAD_URL}/${props.user.resumeUrl}`}
            download
            target='_blank'
            rel='noreferrer'
          >
            View My Resume
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileExp;
