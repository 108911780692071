import React from 'react';

const LoginToggle = ({ showLogin, setShowLogin }) => {
  return (
    <div
      onClick={() => {
        setShowLogin((showLogin) => !showLogin);
      }}
      className='text-center bg-white pb-4 cursor-pointer text-primary font-semibold'
    >
      {showLogin
        ? 'New user? Create a new account.'
        : 'Already have an account? Login instead!'}
    </div>
  );
};

export default LoginToggle;
