import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { signIn, signOut, fetchUser, addUser } from '../redux';
import { Auth } from 'aws-amplify';
import { LoadingSpinner } from '../layout/LoadingSpinner';
import { Rings } from 'react-loading-icons';

const Login = ({
  currentAuthStatus,
  signIn,
  userDetails,
  newUser,
  fetchUser,
  addUser,
  location,
}) => {
  const pathToRedirect = location.state
    ? location.state.from.pathToRedirect || location.state.from
    : '/';
  const [feedbackMessage, setFeedbackMessage] = useState();
  const [loginLoading, setLoginLoading] = useState(false);
  const [processing, setProcessing] = useState(true);
  useEffect(() => {
    if (currentAuthStatus && userDetails.user.cognitoId) {
      <Redirect to={pathToRedirect} />;
    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => {
          fetchUser(user.attributes.sub);
          //Check fetched user
          if (
            !userDetails.user.loading &&
            userDetails.error === 'No user found'
          ) {
            addUser({
              name: user.attributes.name,
              email: user.attributes.email,
              cognitoId: user.attributes.sub,
              partnerId: user.attributes['custom:partnerId'],
              course: user.attributes['custom:course'],
              year: user.attributes['custom:course_year'],
              university: user.attributes['custom:university'],
            });
          }
          if (userDetails.user.cognitoId) {
            signIn();
          }
        })
        .catch(() => {
          setProcessing(false);
          console.log('Not signed in');
        });
    }
  }, [
    currentAuthStatus,
    newUser.user,
    fetchUser,
    signIn,
    userDetails.user.loading,
    userDetails.error,
    userDetails.user.cognitoId,
    addUser,
    pathToRedirect,
  ]);

  async function awsSignIn(username, password) {
    setLoginLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      const cognitoId = user.attributes.sub;
      fetchUser(cognitoId);
      if (!currentAuthStatus && userDetails.user.cognitoId) {
        signIn();
      } else {
        addUser({
          name: user.attributes.name,
          email: user.attributes.email,
          cognitoId: user.attributes.sub,
          partnerId: user.attributes['custom:partnerId'],
          course: user.attributes['custom:course'],
          year: user.attributes['custom:course_year'],
          university: user.attributes['custom:university'],
        });
      }
    } catch (error) {
      setFeedbackMessage(error.message);
      setLoginLoading(false);
    }
  }
  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email'),
      password: Yup.string().required('Password cannot be empty'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      awsSignIn(values.email, values.password);
    },
  });
  const backgroundGradientStyle = {
    background:
      'linear-gradient(to bottom,  #39acb7 0%,#39acb7 70%,white 70%,white 100%)',
  };
  return (
    <>
      {currentAuthStatus && <Redirect to={pathToRedirect} />}
      {processing && <LoadingSpinner />}
      {!processing && (
        <div class='h-screen relative'>
          <div
            class='flex flex-col h-full justify-evenly'
            style={backgroundGradientStyle}
          >
            <div class='flex justify-center'>
              <div class='w-32'>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643001567/uploaded_resources/Belong-min_nqgzjz.png'
                  alt='belong'
                />
              </div>
              <div class='flex'>
                <h1 class='mx-auto my-auto font-black text-white text-5xl'>
                  BELONG
                </h1>
              </div>
            </div>
            <div class='flex flex-col mx-auto  p-4 md:pt-16 md:pl-36 md:pr-36 md:pb-16 bg-white shadow-lg shadow-gray-300 hover:shadow-gray-400 rounded transition-all duration-300'>
              <div class='flex md:ml-16 md:mr-16 w-100'>
                <h1 class='mx-auto p-12 text-2xl'>Log into Belong</h1>
              </div>
              <form onSubmit={loginFormik.handleSubmit}>
                <div class='flex flex-col pt-2 pb-2'>
                  <input
                    class='p-2 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
                    type='text'
                    name='email'
                    placeholder='Email'
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.email}
                  />
                  <p class='text-red-600 text-xs p-0.5'>
                    {loginFormik.errors.email ? loginFormik.errors.email : null}
                  </p>
                  <input
                    class='p-2 mt-4 outline-none rounded border-b border-gray-100 transition-all ease-linear duration-200 hover:border-primary focus:border-primary focus:outline-none'
                    type='password'
                    name='password'
                    placeholder='Password'
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.password}
                  />
                  <p class='text-red-600 text-xs p-0.5'>
                    {loginFormik.errors.password
                      ? loginFormik.errors.password
                      : null}
                  </p>

                  {!loginLoading ? (
                    <button
                      type='submit'
                      className={`mt-8 p-2 text-gray-600 bg-gray-200 transition-all ease-linear duration-100 hover:bg-primary hover:text-white rounded hover:shadow-lg hover:shadow-gray-300`}
                    >
                      LOG IN
                    </button>
                  ) : (
                    <div class='flex p-2'>
                      <div class='mx-auto'>
                        <Rings stroke='#39acb7' speed={1.5} />
                      </div>
                    </div>
                  )}
                </div>
                <div class='flex flex-col'>
                  <div class='mx-auto'>
                    <p class='text-red-600 text-sm p-0.5'>{feedbackMessage}</p>
                  </div>
                </div>
              </form>

              <div class='flex flex-col p-4 mt-8'>
                <Link
                  class='mx-auto text-sm cursor-pointer p-0.5 hover:text-primary'
                  to={{
                    pathname: '/signup',
                    state: { from: pathToRedirect },
                  }}
                >
                  <h1>Don't have an account?</h1>
                </Link>
                <Link
                  class='mx-auto text-sm cursor-pointer p-0.5 hover:text-primary'
                  to='/resetPassword'
                >
                  <h1>Forgot password?</h1>
                </Link>
              </div>
            </div>
          </div>

          <div class='flex absolute bottom-0 w-full'>
            <div class='mx-auto p-1 pb-0 bg-primary rounded-tl-md rounded-tr-md cursor-default'>
              <p class='font-semibold text-xs text-white pl-2 pr-2'>
                An Ingenious Faces Marketplace
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuthStatus: state.auth.authStatus,
    userDetails: state.user,
    newUser: state.newUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    fetchUser: (cognitoId) => dispatch(fetchUser(cognitoId)),
    addUser: (userInfo) => dispatch(addUser(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
