import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import Profile from '../profile';
import Nav from '../layout/Nav';
import FirstScreen from './FirstScreen';
import TransitionScreen from './TransitionScreen';
import { storeAssessment } from '../redux';
import { API_URL } from '../API_LINKS';

const Index = ({ user, assessment, storeAssessment }) => {
  let { code } = useParams();
  const assessmentData = assessment.assessment;

  useEffect(() => {
    if (code && !assessmentData) {
      axios
        .get(API_URL + '/assessment', {
          params: { code: code },
        })
        .then((res) => {
          storeAssessment({
            questions: res.data[0]?.questions,
            assessmentId: res.data[0]?._id,
            status: res.data[0]?.status,
          });
        });
    }
  }, [code, assessmentData, storeAssessment]);

  const assessmentStatus =
    user.masterclassAssessment && user.masterclassAssessment.finishTime
      ? 'Profile'
      : 'Assessment';

  const [assessmentActive, setAssessmentActive] = useState(assessmentStatus);

  return (
    <div className='space-y-8'>
      <Nav assessmentActive={assessmentActive} />
      <div className='p-4 md:px-4 lg:px-24'>
        {assessmentActive === 'Assessment' && (
          <FirstScreen
            setAssessmentActive={setAssessmentActive}
            user={user}
            questions={assessmentData?.questions}
            assessmentId={assessmentData?.assessmentId}
            status={assessmentData?.status}
          />
        )}
        {assessmentActive === 'Transition' && (
          <TransitionScreen setAssessmentActive={setAssessmentActive} />
        )}
        {assessmentActive === 'Profile' && <Profile />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user, assessment: state.assessment };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeAssessment: (assessment) => dispatch(storeAssessment(assessment)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
