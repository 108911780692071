import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import { storeAssessment } from '../redux';
import { API_URL } from '../API_LINKS';
import EnterCode from './EnterCode';
import { LoadingSpinner } from '../layout/LoadingSpinner';
import { PartnerSignup } from '../PartnerSignup';

const Index = ({ storeAssessment }) => {
  const [masterClassCode, setMasterClassCode] = useState();
  const [masterClassData, setMasterClassData] = useState();
  const [loading, setLoading] = useState(true);

  let { code } = useParams();
  useMemo(() => {
    setMasterClassCode(code);
  }, [code]);

  useMemo(() => {
    if (masterClassCode) {
      axios
        .get(API_URL + '/assessment', {
          params: { code: masterClassCode },
        })
        .then((res) => {
          setMasterClassData(res.data);
          storeAssessment({
            questions: res.data[0]?.questions,
            assessmentId: res.data[0]?._id,
            status: res.data[0]?.status,
          });
          setLoading(false);
        });
    }
  }, [storeAssessment, masterClassCode]);
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : masterClassData?.length ? (
        <PartnerSignup
          partnerCode={masterClassData[0].partnerId.code}
          partner={masterClassData[0].partnerId}
          code={masterClassCode}
        />
      ) : (
        <EnterCode wrongCode={true} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeAssessment: (assessment) => dispatch(storeAssessment(assessment)),
  };
};

export default connect(null, mapDispatchToProps)(Index);
