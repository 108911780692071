import React, { useEffect, useState } from 'react';
import CategoryAssessment from './CategoryAssessment';

const Assessment = ({
  assessmentQuestion,
  setAssessmentActive,
  autoSubmit,
  assessmentId,
}) => {
  const localfinalSubmission = JSON.parse(
    localStorage.getItem('localfinalSubmission') || '[]'
  );
  const [finalSubmission, setFinalSubmation] = useState(
    localfinalSubmission.length
      ? localfinalSubmission
      : assessmentQuestion.map((ass) => {
          return ass.questions;
        })
  );

  useEffect(() => {
    if (localfinalSubmission.length) {
      setFinalSubmation(localfinalSubmission);
    } else {
      localStorage.setItem(
        'localfinalSubmission',
        JSON.stringify(finalSubmission)
      );
    }
  }, []);

  const [currentCategory, setCurrentCategory] = useState(
    parseInt(localStorage.getItem('localCurrentCategory')) || 0
  );

  return (
    <div>
      {currentCategory === 0 && (
        <CategoryAssessment
          categoryQuestions={assessmentQuestion[0]}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          finalSubmission={finalSubmission}
          setFinalSubmation={setFinalSubmation}
          setAssessmentActive={setAssessmentActive}
          autoSubmit={autoSubmit}
          assessmentId={assessmentId}
        />
      )}
      {currentCategory === 1 && (
        <CategoryAssessment
          categoryQuestions={assessmentQuestion[1]}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          finalSubmission={finalSubmission}
          setFinalSubmation={setFinalSubmation}
          setAssessmentActive={setAssessmentActive}
          autoSubmit={autoSubmit}
          assessmentId={assessmentId}
        />
      )}
      {currentCategory === 2 && (
        <CategoryAssessment
          categoryQuestions={assessmentQuestion[2]}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          finalSubmission={finalSubmission}
          setFinalSubmation={setFinalSubmation}
          setAssessmentActive={setAssessmentActive}
          autoSubmit={autoSubmit}
          assessmentId={assessmentId}
        />
      )}
      {currentCategory === 3 && (
        <CategoryAssessment
          categoryQuestions={assessmentQuestion[3]}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          finalSubmission={finalSubmission}
          setFinalSubmation={setFinalSubmation}
          setAssessmentActive={setAssessmentActive}
          autoSubmit={autoSubmit}
          assessmentId={assessmentId}
        />
      )}
    </div>
  );
};

export default Assessment;
