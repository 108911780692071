import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';

const CongratsRoles = () => {
  return (
    <div className='text-center text-lg'>
      <div>
        <p className='text-primary text-xl font-bold'>Congratulations!</p>
        <div>
          <BsFillCheckCircleFill className='text-green-700 text-3xl mx-auto my-4' />
        </div>
        <p>
          You have download & shared your Employability Certificate & Score
          <p className='my-4 font-semibold'>
            You are in the{' '}
            <span className='text-primary'>
              top 15% of the most hired profiles
            </span>{' '}
            in the Belong Talent Pool
          </p>
          <div>
            <p>
              Apply Now to Job/ Internship Opportunities with Our Global
              Recruiters
            </p>
            <a
              href='http://localhost:3001/jobs'
              target='_blank'
              rel='noreferrer noopener'
            >
              <button className='text-white bg-primary px-2 rounded shadow mt-2'>
                Get Hired
              </button>
            </a>
          </div>
        </p>
      </div>
    </div>
  );
};

export default CongratsRoles;
