import React, { useState } from 'react';
import Assessment from '../assessments';

const FirstScreen = ({
  setAssessmentActive,
  questions,
  assessmentId,
  status,
}) => {
  const [showAssesment, setShowAssesment] = useState(false);

  return (
    <>
      <div>
        {showAssesment ? (
          <Assessment
            setAssessmentActive={setAssessmentActive}
            assessmentQuestion={questions}
            assessmentId={assessmentId}
          />
        ) : (
          <div className='flex flex-col'>
            <div className='flex justify-between'>
              <p className='font-medium text-gray-600 text-lg py-1'>
                Employability Assessment
              </p>
            </div>

            <div className='bg-gray-50 p-3 mt-6'>
              <p className='text-primary font-semibold'>
                The following assessment evaluates your Employability Quotient
                based on the 4 most essential workplace skills{' '}
              </p>
              <ul className='list-disc px-6 text-primary font-semibold mt-4'>
                <li className='py-1'>
                  <span className='text-gray-400'>
                    Business Communication - Making clear, impactful and
                    effective communication with colleagues and stakeholders
                  </span>
                </li>
                <li className='py-1'>
                  <span className='text-gray-400'>
                    Reasoning & Creativity - Observant and critical with
                    information to find clear solution and process for problems
                  </span>
                </li>
                <li className='py-1'>
                  <span className='text-gray-400'>
                    Observation & Analytics - Thinking outside the box and come
                    up with new and effective ideas as business solutions
                  </span>
                </li>
                <li className='py-1'>
                  <span className='text-gray-400'>
                    Self Awareness - Awareness of your strengths and abilities
                    to harbor exponential career growth and development
                  </span>
                </li>
              </ul>
              <p className='text-primary font-semibold mt-6'>
                Why take this assessment?
              </p>
              <ul className='list-disc px-6 text-gray-400 font-semibold mt-4'>
                <li className='py-1'>
                  Get your Employability Proof Certificate and share it with
                  your network
                </li>
                <li className='py-1'>
                  Showcase your employability potential and know your University
                  Leaderboard Rank
                </li>
                <li className='py-1'>
                  Unlock on the Spot Internship and Job opportunities with
                  leading Global companies
                </li>
              </ul>
            </div>
            <div className='flex justify-end'>
              {status === 'active' ? (
                <div className=' mt-10 bg-primary-light hover:bg-primary text-primary hover:text-white font-semibold px-8 py-2 text-base md:text-lg rounded'>
                  <button
                    onClick={() => {
                      setShowAssesment(true);
                    }}
                  >
                    Get Started
                  </button>
                </div>
              ) : (
                <div className='bg-gray-300 mt-10 px-8 py-2 rounded'>
                  <button disabled>Get Started</button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FirstScreen;
