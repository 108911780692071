import React from 'react';
import Benefits from './Benefits';
import CongratsPrograms from './CongratsPrograms';
import CongratsRoles from './CongratsRoles';
import Steps from './Steps';
import ShowRoles from './ShowRoles';
import ShowChallenges from './ShowChallenges';

const FinalScreen = () => {
  const score = 90;
  const cutOffScore = 80;
  const showRoles = score >= cutOffScore;
  const name = 'Abhishek';
  return (
    <div className='px-16 py-8'>
      {showRoles ? (
        <>
          <CongratsRoles />
          <ShowRoles />
          {/* <ShowChallenges /> */}
        </>
      ) : (
        <>
          <CongratsPrograms name={name} />
          <Benefits />
          <Steps />
          <p className='text-primary text-xl font-semibold text-center mt-8'>
            Pick A Company Challenge Matching with Your Domain & Skillset
          </p>
        </>
      )}
    </div>
  );
};

export default FinalScreen;
