import React, { useState, useEffect } from 'react';

import { AiFillClockCircle } from 'react-icons/ai';

import Card from './Card';
import Navigation from './Navigation';
import Progress from './Progress';
import Preview from './Preview';

const Questions = (props) => {
  const [showPreview, setShowPreview] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [autoNextSection, setAutoNextSection] = useState(false);
  const question = props.userSubmission[currentQuestion].question;
  const options = props.userSubmission[currentQuestion].options;
  const image = props.userSubmission[currentQuestion].image || [];
  const isInputType =
    props.userSubmission[currentQuestion].responseType === 'text';

  const [time, setTime] = useState({});

  useEffect(() => {
    startTimer();
  }, []);
  const startTimer = () => {
    const countDownTime = Date.now() + props.timeGiven * 60 * 1000;
    const interval = setInterval(() => {
      const now = new Date();
      const distance = countDownTime - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setAutoNextSection(true);
        clearInterval(interval);
        setTime({ minutes: 0, seconds: 0 });
      } else {
        setTime({ minutes: minutes, seconds: seconds });
      }
    }, 1000);
  };

  return (
    <>
      {showPreview ? (
        <Preview
          finalSubmission={props.finalSubmission}
          autoSubmit={props.autoSubmit}
          setAssessmentActive={props.setAssessmentActive}
          assessmentId={props.assessmentId}
        />
      ) : (
        <>
          <div className='flex justify-between mb-6'>
            <p className='font-medium text-gray-600 text-lg py-1'>
              {props.currentCategory + 1}/4 {props.title}
            </p>
            <div className='flex text-lg items-center bg-gray-50 px-2 sm:spx-4 py-1 text-primary'>
              <AiFillClockCircle />
              <p className='ml-1 text-gray-600 text-base'>
                {' '}
                : {time.minutes} : {time.seconds}
              </p>
            </div>
          </div>
          <Card
            question={question}
            options={options}
            currentQuestion={currentQuestion}
            image={image}
            setUserSubmission={props.setUserSubmission}
            userSubmission={props.userSubmission}
            timeGiven={props.timeGiven}
            title={props.title}
            finalSubmission={props.finalSubmission}
            setFinalSubmation={props.setFinalSubmation}
            currentCategory={props.currentCategory}
            setAutoNextSection={setAutoNextSection}
            isInputType={isInputType}
          />
          <Navigation
            setCurrentQuestion={setCurrentQuestion}
            currentQuestion={currentQuestion}
            totalQuestions={props.totalQuestions}
            userSubmission={props.userSubmission}
            currentCategory={props.currentCategory}
            setCurrentCategory={props.setCurrentCategory}
            finalSubmission={props.finalSubmission}
            setAssessmentActive={props.setAssessmentActive}
            autoSubmit={props.autoSubmit}
            autoNextSection={autoNextSection}
            setShowPreview={setShowPreview}
            assessmentId={props.assessmentId}
          />
          <Progress
            currentQuestion={currentQuestion + 1}
            totalQuestions={props.totalQuestions}
          />
        </>
      )}
    </>
  );
};

export default Questions;
