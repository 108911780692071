import React from 'react';
import { AiFillClockCircle } from 'react-icons/ai';

const Instruction = ({
  setInstruction,
  startTimer,
  totalQuestion,
  assessmentQuestion,
}) => {
  const CREATIVITY = assessmentQuestion.filter((ass) => {
    return ass.category === 'CREATIVITY';
  })[0].questions.length;

  const SELF_AWARENESS = assessmentQuestion.filter((ass) => {
    return ass.category === 'SELF_AWARENESS';
  })[0].questions.length;
  const ANALYTICAL_SKILLS = assessmentQuestion.filter((ass) => {
    return ass.category === 'ANALYTICAL_SKILLS';
  })[0].questions.length;
  const COMMUNICATION = assessmentQuestion.filter((ass) => {
    return ass.category === 'COMMUNICATION';
  })[0].questions.length;

  return (
    <div>
      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <p className='font-medium text-gray-600 text-lg py-1'>Assessment</p>
          <div className='flex text-lg items-center bg-gray-50 px-4 py-1 text-primary'>
            <AiFillClockCircle />
            <p className='ml-1 text-gray-600 text-base'> : 20:00</p>
          </div>
        </div>

        <div className='bg-gray-50 p-3 mt-6'>
          <p className='text-primary font-semibold'>General Instuctions - </p>
          <ul className='list-disc px-3 sm:px-6 text-primary font-semibold mt-6'>
            <li className='py-1'>
              <span className='text-gray-400'>Total Questions : </span>
              <span className='text-gray-600'> {totalQuestion} </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>Total Duration : </span>
              <span className='text-gray-600'>20 min</span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>No. of Sections: </span>
              <span className='text-gray-600'>4</span>
            </li>
            <ul className='my-4 list-disc'>
              <span className='text-gray-600'>
                The assessment is divided into four sections
              </span>
              <li className='py-1 ml-4'>
                <span className='text-gray-400'>Business Communication -</span>
                <span className='text-gray-600'>
                  {COMMUNICATION} questions in 6 minutes
                </span>
              </li>
              <li className='py-1 ml-4'>
                <span className='text-gray-400'>
                  Reasoning {'&'} Creativity -
                </span>
                <span className='text-gray-600'>
                  {CREATIVITY} questions in 8 minutes
                </span>
              </li>
              <li className='py-1 ml-4'>
                <span className='text-gray-400'>
                  Observation {'&'}Analytics -
                </span>
                <span className='text-gray-600'>
                  {ANALYTICAL_SKILLS} questions in 3 minutes
                </span>
              </li>
              <li className='py-1 ml-4'>
                <span className='text-gray-400'>Self Awareness -</span>
                <span className='text-gray-600'>
                  {SELF_AWARENESS} questions in 3 minutes
                </span>
              </li>
            </ul>
            <li className='py-1'>
              <span className='text-gray-400'>
                Click on "Start Assessment" to begin the assessment
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                Each section has a timer and you can submit a section by
                clicking on the " Next Sections" Button. If the timer runs out,
                your section response will be auto submitted and you will be
                redirected to the next section.
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                You can navigate through questions in one section using
                Previous/ Next Buttons
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                Remember to finish on time and click on the submit button. If
                the timer runs out while you're still finishing the assessment,
                your answer will be auto-submitted
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                You can preview and edit your answers at the end of the all the
                sections if you are able to finish all your sections before a
                total time of 20 mins
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                After submitting your assessment, you will be able to see your
                scores
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                To download the certificate, you will need to complete the
                profile section after completing the assessment
              </span>
            </li>
          </ul>
          <p className='text-primary font-semibold mt-4'>Note - </p>
          <ul className='list-disc px-6 text-gray-400 font-semibold mt-6'>
            <li className='py-1'>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            <li className='py-1'>
              In case of any network issues, please contact the facilitator
            </li>
          </ul>
        </div>
        <div className='flex justify-end'>
          <div className=' mt-10 bg-primary-light hover:bg-primary text-primary hover:text-white font-semibold px-8 py-2 text-base md:text-lg rounded'>
            <button
              onClick={() => {
                setInstruction(false);
                startTimer();
              }}
            >
              Start Assessment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
