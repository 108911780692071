import React from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

const ExploreRoles = () => {
  const arrowStyle = 'text-primary text-5xl';
  const imageStyle = 'h-10 object-contain';
  return (
    <div className='p-4 relative border-2 shadow-md rounded-lg text-center'>
      <div className='flex justify-center gap-8'>
        <p className='text-primary  flex items-center h-12 font-semibold'>
          Explore More Jobs
        </p>
        <HiOutlineArrowNarrowRight className={arrowStyle} />
      </div>
      <div className='grid grid-rows-2 grid-flow-col gap-4 mt-4'>
        <img
          src='http://res.cloudinary.com/vishal8802/image/upload/v1633670486/test/js4xui86psf87vqy3a0s.png'
          alt='logo'
          className={imageStyle}
        />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1647273623/uploaded_resources/wllzvp4ut4ywlglr4vug_tgfsfo.jpg'
          alt='logo'
          className={imageStyle}
        />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1617959921/uploaded_resources/Ideaspice_mmisoz.png'
          alt='logo'
          className={imageStyle}
        />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1625556779/uploaded_resources/WhatsApp_Image_2021-07-06_at_1.02.35_PM_gfgzqj.jpg'
          alt='logo'
          className={imageStyle}
        />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1620742231/uploaded_resources/memomoti-logo_rhtbqo.png'
          alt='logo'
          className={imageStyle}
        />
        <img
          src='http://res.cloudinary.com/belong/image/upload/v1649928348/uploaded_resources/Rubys_coffee_logo_uktkuk.png'
          alt='logo'
          className={imageStyle}
        />
      </div>
    </div>
  );
};

export default ExploreRoles;
