import React from 'react';
import Role from './layout/Role';
import axios from 'axios';
import { useQuery } from 'react-query';
import { API_URL } from '../API_LINKS';
import { LoadingSpinner } from '../layout/LoadingSpinner';
import ExploreRoles from './layout/ExploreRoles';

const fetchAllOpportunities = async (keys) => {
  const res = await axios.get(API_URL + '/opportunities', {});
  return res;
};

const ShowRoles = () => {
  const { status, data } = useQuery(
    ['fetchAllOpportunitiesKey'],
    fetchAllOpportunities
  );
  return (
    <div>
      {status === 'loading' && <LoadingSpinner />}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <div className='my-8 grid md:grid-cols-3 gap-8 md:gap-16'>
          {data.data.map((role) => {
            return (
              <Role
                key={role._id}
                roleTitle={role.roleDetails.roleTitle}
                roleType={role.roleDetails.roleType}
                logo={role.companyId.logoUrl}
                slug={role.slug}
              />
            );
          })}
          <ExploreRoles />
        </div>
      )}
    </div>
  );
};

export default ShowRoles;
