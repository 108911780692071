import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../API_LINKS';
import { connect } from 'react-redux';

const Navigation = (props) => {
  const [serverResponse, setServerResponse] = useState('');
  const submitQuestions = () => {
    setServerResponse('Submitting your responses');
    axios
      .post(API_URL + '/assessment/user-submission', {
        responses: props.finalSubmission.flat(),
        userId: props.user._id,
        startTime: parseInt(localStorage.getItem('localCountDownTime')),
        finishTime: +Date.now(),
        assessmentype: 'masterclass',
        assessmentId: props.assessmentId,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('localShuffleAssessmentQuestion');
          localStorage.removeItem('localCountDownTime');
          localStorage.removeItem('localfinalSubmission');
          localStorage.removeItem('localCurrentCategory');
          props.setAssessmentActive('Transition');
          setServerResponse('Submission successful!, Generating report');
        }

        //Show report from response
      })
      .catch((res) => setServerResponse('Could not submit, please try again.'));
  };

  useEffect(() => {
    if (props.autoSubmit) {
      submitQuestions();
    }
  }, [props.autoSubmit]);

  const navStyle = 'font-semibold text-primary cursor-pointer';

  const setPrevious = () => {
    props.currentQuestion > 0 &&
      props.setCurrentQuestion(props.currentQuestion - 1);
  };
  const setNext = () => {
    if (props.currentQuestion + 1 < props.totalQuestions) {
      props.setCurrentQuestion((pre) => pre + 1);
    } else {
      if (props.currentCategory < 3) {
        localStorage.setItem('localCurrentCategory', props.currentCategory + 1);
        props.setCurrentCategory(props.currentCategory + 1);
      } else {
        submitQuestions();
      }
    }
  };

  useEffect(() => {
    if (props.autoNextSection) {
      if (props.currentCategory < 3) {
        localStorage.setItem('localCurrentCategory', props.currentCategory + 1);
        props.setCurrentCategory(props.currentCategory + 1);
      } else {
        props.setShowPreview(true);
      }
    }
  }, [props.autoNextSection]);

  return (
    <div>
      <div className='fixed bottom-0 right-1 left-1 flex z-10 w-full sm:static'>
        <div className='justify-between flex bg-gray-50 p-6 w-full mr-4 text-2xl font-semibold'>
          <p
            className={navStyle}
            onClick={() => {
              setPrevious();
            }}
          >
            {props.currentQuestion > 0 ? 'Previous' : ''}
          </p>
          <div
            className={navStyle}
            onClick={() => {
              setNext();
            }}
          >
            {props.currentQuestion + 1 < props.totalQuestions ? (
              <p>Next</p>
            ) : props.currentCategory < 3 ? (
              <p>Next Section</p>
            ) : (
              <p className='bg-primary-light p-2 rounded'>Submit</p>
            )}
          </div>

          {props.currentQuestion + 1 === props.totalQuestions &&
            props.currentCategory === 3 && (
              <p
                className={navStyle}
                onClick={() => {
                  props.setShowPreview(true);
                }}
              >
                Preview
              </p>
            )}
        </div>
      </div>

      <p className='my-2'>{serverResponse}</p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps)(Navigation);
