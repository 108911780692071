import classNames from 'classnames';
import React from 'react';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';
//import InputBox from './InputBox';

const Card = (props) => {
  const AnswerInput = (inputValue) => {
    const data = props.userSubmission[props.currentQuestion];
    data.userInput = inputValue;

    let tempObj = [...props.userSubmission];
    tempObj[props.currentQuestion] = data;
    props.setUserSubmission(tempObj);
    let finalTemp = [...props.finalSubmission];
    finalTemp[props.currentCategory] = tempObj;
    localStorage.setItem('localfinalSubmission', JSON.stringify(finalTemp));
    props.setFinalSubmation(finalTemp);
  };

  const markOption = (optionSelectedByUser, indexOfOptionSelectedByUser) => {
    const data = props.userSubmission[props.currentQuestion];
    const { maxSelect } = data;
    const selectedOptionsCount = data.options.filter(
      (el) => el.selected
    ).length;

    if (data.options[indexOfOptionSelectedByUser].hasOwnProperty('selected')) {
      delete data.options[indexOfOptionSelectedByUser].selected;
    } else if (selectedOptionsCount < maxSelect) {
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    } else if (selectedOptionsCount === maxSelect) {
      data.options.forEach((option) => {
        if (option.hasOwnProperty('selected')) {
          delete option.selected;
        }
      });
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    }

    let tempObj = [...props.userSubmission];
    tempObj[props.currentQuestion] = data;
    props.setUserSubmission(tempObj);
    let finalTemp = [...props.finalSubmission];
    finalTemp[props.currentCategory] = tempObj;
    localStorage.setItem('localfinalSubmission', JSON.stringify(finalTemp));
    props.setFinalSubmation(finalTemp);
  };

  return (
    <div>
      <p className='mb-6 font-semibold text-gray-500 text-lg'>
        {props.currentQuestion + 1}. {props.question}
      </p>
      <div className='grid grid-flow-col auto-cols-fr gap-4'>
        {props.image.map((imageName) => {
          return (
            <img
              key={imageName}
              className='rounded shadow h-full'
              src={imageName}
              alt='Question'
            />
          );
        })}
      </div>
      <div className='space-y-4 bg-gray-50 p-6'>
        {props.isInputType ? (
          <div>
            <input
              type='text'
              placeholder='Your Answer'
              className='p-2 rounded w-full md:w-1/2'
              value={props.userSubmission[props.currentQuestion].userInput}
              onChange={(e) => {
                AnswerInput(e.target.value);
              }}
            />
            {/* <InputBox
              AnswerInput={AnswerInput}
              userAnswer={props.userSubmission[props.currentQuestion].userInput}
            /> */}
          </div>
        ) : (
          props.options.map((option, index) => {
            return (
              <div
                key={option._id}
                onClick={() => {
                  markOption(option._id, index);
                }}
                className={classNames(
                  {
                    'text-blue-500': option.selected === true,
                  },
                  'cursor-pointer p-2 flex items-center font-semibold text-gray-500'
                )}
              >
                {option.selected ? (
                  <span className='pr-2'>
                    <BiRadioCircleMarked />
                  </span>
                ) : (
                  <span className='pr-2'>
                    <BiRadioCircle />
                  </span>
                )}

                {option.option}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Card;
