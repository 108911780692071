import React, { useEffect } from 'react';

import { FaCheckCircle } from 'react-icons/fa';

const TransitionScreen = ({ setAssessmentActive }) => {
  useEffect(() => {
    let countDownTime = Date.now() + 30 * 1000;

    const interval = setInterval(() => {
      const now = new Date();
      const distance = countDownTime - now;

      if (distance < 0) {
        setAssessmentActive('Profile');
        clearInterval(interval);
      }
    }, 1000);
  });
  return (
    <div className='text-center mx-auto'>
      <p className='text-center text-green-500 my-6 font-semibold text-xl'>
        Congratulations!{' '}
      </p>
      <FaCheckCircle className='text-green-500 text-5xl mx-auto' />
      <p className='mx-4 sm:mx-10 text-center text-lg my-4'>
        To access the score and certificate, Please complete your profile
        section{' '}
      </p>
      <button
        onClick={() => setAssessmentActive('Profile')}
        className='bg-primary px-4 py-2 rounded-lg'
      >
        Complete Profile Now
      </button>
    </div>
  );
};

export default TransitionScreen;
