import React from 'react';

const ReportPreview = () => {
  return (
    <div className='bg-gray-100 rounded-2xl shadow px-2 py-4 md:p-8 h-full items-center flex'>
      Complete the assessment to get your customised Employability Assessment
      Report
    </div>
  );
};

export default ReportPreview;
