import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import addUserReducer from './user/addUserReducer';
import fetchUserReducer from './user/fetchUserReducer';
import assessmentReducer from './assessment/assessmentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: fetchUserReducer,
  newUser: addUserReducer,
  assessment: assessmentReducer,
});

export default rootReducer;
