import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { Auth } from 'aws-amplify';
import * as Yup from 'yup';

const LoginForm = () => {
  const inputStyle = 'shadow p-2 w-full';
  const labelStyle = 'text-gray-600 mb-2 inline-block';
  const [serverResponse, setServerResponse] = useState();
  const [loginSuccess, setLoginSuccess] = useState(false);

  async function awsSignIn(username, password, setSubmitting) {
    try {
      const user = await Auth.signIn(username, password);
      user && setLoginSuccess(true);
    } catch (error) {
      setServerResponse(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  return loginSuccess ? (
    <Redirect to='/assessment' />
  ) : (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required('Please enter your Email'),
        password: Yup.string()
          .required('Please enter your Password')
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Incorrect Password'
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setServerResponse('Logging you in...');
        awsSignIn(values.email, values.password, setSubmitting);
      }}
    >
      <Form className='bg-white space-y-8 p-4 md:p-8 shadow-lg rounded'>
        <div className='grid grid-flow-auto grid-cols-1 sm:grid-cols-2 gap-4'>
          <div>
            <label htmlFor='email' className={labelStyle}>
              Email
            </label>
            <Field
              name='email'
              type='email'
              placeholder='Email'
              className={inputStyle}
            />
            <ErrorMessage name='email' />
          </div>
          <div>
            <label htmlFor='password' className={labelStyle}>
              Password
            </label>
            <Field
              name='password'
              type='password'
              placeholder='Password'
              className={inputStyle}
            />
            <ErrorMessage name='password' />
          </div>
        </div>

        <div className='text-center'>
          <button
            type='submit'
            className='px-4 py-2 shadow bg-primary-light rounded'
          >
            Submit
          </button>
          <div className='my-4'>{serverResponse}</div>
        </div>
        <div>
          <Link
            class='mx-auto text-sm cursor-pointer p-0.5 hover:text-primary'
            to='/resetPassword'
          >
            <h1>Forgot password?</h1>
          </Link>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;
