import React, { useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../API_LINKS';
import { Redirect } from 'react-router-dom';

const EnterCode = ({ wrongCode }) => {
  const [redirect, setRedirect] = useState(false);
  const [code, setCode] = useState();
  const inputStyle =
    'shadow p-2 w-full border-gray-200 border rounded text-2xl';
  function customHandleChange(e, setFieldValue, values) {
    const { value } = e.target;
    setFieldValue('code', value.trim().toLowerCase());
  }

  return redirect ? (
    <Redirect to={`/${code}`} />
  ) : (
    <div className='container px-4'>
      <div className='grid place-items-center h-screen'>
        <div className='space-y-6'>
          <div>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
              alt='Belong'
              className='hidden sm:block w-40 mx-auto'
            />
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643351800/uploaded_resources/logo-small_jt2fqr.png'
              alt='Belong'
              className='sm:hidden h-16 mx-auto'
            />
          </div>
          <p className='text-2xl font-semibld'>
            {wrongCode
              ? 'Please enter a valid masterclass code'
              : 'Please enter your masterclass code'}
          </p>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={{ code: '' }}
              validationSchema={Yup.object({
                code: Yup.string().required('Please enter a code'),
              })}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                axios
                  .get(API_URL + '/assessment', {
                    params: { code: values.code },
                  })
                  .then((res) => {
                    setErrors({
                      code: res.data.length ? 'Redirecting' : 'Incorrect code',
                    });
                    if (res.data.length) {
                      setCode(values.code);
                      setRedirect(true);
                    }
                  })
                  .catch((res) => {
                    setErrors({ code: 'Error!' });
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <input
                    type='text'
                    name='code'
                    id='code'
                    placeholder='abc-def-ghi'
                    className={inputStyle}
                    onChange={(e) => {
                      handleChange(e);
                      customHandleChange(e, setFieldValue, values);
                    }}
                  />
                  <div className='space-y-8'>
                    <p className='text-red-700 mt-2'>
                      {errors.code && touched.code && errors.code}
                    </p>
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='bg-primary-light shadow hover:bg-primary px-4 py-2 rounded'
                    >
                      {isSubmitting ? 'Loading...' : 'Submit'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterCode;
