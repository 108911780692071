import React from 'react';
import { css } from '@emotion/react';
import PropagateLoader from 'react-spinners/PropagateLoader';

export const LoadingSpinner = ({ loading }) => {
  const override = css`
    display: flex;
    margin: 0 auto;
    color: #10adb9;
  `;
  return (
    <div className='flex items-center h-screen'>
      <PropagateLoader
        color={'#10adb9'}
        loading={loading}
        css={override}
        size={15}
      />
    </div>
  );
};
